import React, { createContext, useReducer, useContext, useEffect } from "react"

const GlobalContext = createContext()
const GlobalDispatchContext = createContext()

const globalReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_THEME": {
      return { ...state, currentTheme: action.theme }
    }
    case "TOGGLE_CURSOR": {
      return { ...state, cursorType: action.cursorType }
    }
    default: {
      throw new Error(`Unhandled actipn type: ${action.type}`)
    }
  }
}

export const GlobalProvider = ({ children, initState }) => {
  let initTheme = null
  if (typeof window !== "undefined") {
    initTheme = JSON.parse(window.localStorage.getItem("themeState")) || {
      currentTheme: "dark",
    }
  }

  const customInitState = initState
    ? initState
    : {
        ...initTheme,
        cursorType: false,
      }

  const [state, dispatch] = useReducer(globalReducer, customInitState)

  useEffect(() => {
    window.localStorage.setItem(
      "themeState",
      JSON.stringify({ currentTheme: state.currentTheme })
    )
  }, [state.currentTheme])

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalContext.Provider value={state}>{children}</GlobalContext.Provider>
    </GlobalDispatchContext.Provider>
  )
}

export const useGlobalContext = () => useContext(GlobalContext)
export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext)
